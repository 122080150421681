<template>
  <div class="col-12">
    <br><br>
    <div class="col-12 col-lg-6 c">
        <div class="card card-body">
            <div class="alert alert-success text-center g">
                تم ارسال كود الدخول الى {{user.phone}} على الواتسآب.
            </div>
            <input type="number" v-model="code" class="form-control form-control-lg text-center" placeholder="كود الدخول هنا...">
            <div class="col-12 text-center g">
                <button class="btn btn-success" @click="check()">
                    التحقق من الكود
                </button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            code: ""
        }
    },
    created(){
        var g = this;
        $.post(api + '/admin/auth/otp', {
            jwt: this.user.jwt
        }).then(function(r){})
    },
    methods: {
        check(){
            var g = this;
            $.post(api + '/admin/auth/otp-check', {
                jwt: this.user.jwt,
                code: g.code,
                otp_code: localStorage.getItem("otp_code")
            }).then(function(r){
                if(r.status == 100){
                    window.location = '/'
                }else{
                    alert(r.response, 200)
                }
            })
        }
    }
}
</script>

<style>

</style>